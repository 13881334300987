nav {
  background-image: url('../images/html.jpg'); 
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; 
}

nav li {
  margin: 0;
}

nav a {
  padding: 10px 20px;
  color: #ffffff;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

nav a:hover {
  background-color: rgba(0, 0, 0, 0.8); 
  color: #FFD700; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  nav ul {
    gap: 20px; 
  }
}

@media (max-width: 480px) {
  nav ul {
    flex-direction: column; /* Stack links vertically */
  }

  nav a {
    padding: 8px 15px;
  }
}
