.experiences-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa; 
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .experiences-container h2 {
    color: #2c3e50; 
    margin-bottom: 20px;
  }
  
  .experiences-container .date {
    color: #95a5a6;
    font-style: italic;
    margin-bottom: 15px;
  }
  
  .experiences-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .experiences-container li {
    margin-bottom: 8px;
    color: #2c3e50;
    font-size: 16px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .experiences-container {
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .experiences-container {
      padding: 10px;
    }
  }
  