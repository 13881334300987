.Susana {
  text-decoration: none;
  color: #34495e;
}

.Susana:hover {
  color: purple; 
}

.master-project-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa; 
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);


}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.image-grid img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-grid img:hover {
  transform: scale(1.05);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.lightbox-close {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.lightbox-arrow {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: translateY(-50%);
}

.lightbox-arrow.left {
  left: 20px;
}

.lightbox-arrow.right {
  right: 20px;
}
